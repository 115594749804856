<template>
  <div class="ar-experience">
    <div class="ar-experience__header">
      <Tip />

      <div v-if="inSphere && !prizeNotify" class="ar-view__tip">
        <Tip :backFn="fromSphere" :hideBack="!inSphere || prizeNotify || !canLeaveTheSphere"/>
      </div>
      <Tip v-if="mapShown" text="Richte deine Kamera auf den H&M HOME QR-Code, um die Animation freizuschalten." />
      <Tip :backFn="fromSphere" :hideBack="!inSphere || !canLeaveTheSphere" v-if="prizeNotify && !mapShown" text="Klicken Sie auf die Schaltfläche unten rechts, um Ihre Geschenkkarte zu erhalten!" />
    </div>
<!--    <div>
      <div v-if="keyDirection"> keyDirection</div>
      <div v-if="inSphere"> inSphere</div>
      <div v-if="prizeNotify"> prizeNotify</div>
      <div v-if="canLeaveTheSphere"> canLeaveTheSphere</div>
      <div v-if="lost"> lost</div>
      <div v-if="sawPrize"> sawPrize</div>
    </div>-->
    <div class="clickable-area" :ontouchend="clickedClickable" :ontouchstart="nope" v-if="clickable"></div>
    <div class="ar-experience__map" v-if="mapShown">
      <Map />
    </div>
<!--    <div class="ar-experience__qr-frame" v-if="scanFrame">
      <ScanQR />
    </div>-->
    <div class="ar-experience__footer">
      <CameraControls :centerButton="centerButton"
                      :touchstart="this.down"
                      :touchend="this.up"
                      :qrclick="this.qrclick"
                      :mapclick="this.mapclick"
                      :prizeNotify="this.prizeNotify"
      />
    </div>
  </div>
</template>

<script>
import Tip from '@/components/Tip.vue'
import CameraControls from '@/components/CameraControls.vue'
import Map from '@/components/Map.vue'
import ScanQR from '@/assets/images/scanQRFrame.svg?inline';
export default {
  name: 'ArExperience',
  components: {
    Tip,
    CameraControls,
    Map,
    ScanQR
  },
  start: 0,
  data () {
    return {
      centerButton: 'record',
      mapShown: false,
      cameraShown: false,
      scanFrame: true,
      videoRecording: false,
      prizeNotify: false,
      lost: false,
      clickable: false,
      inSphere: false,
      sawPrize: false,
      keyDirection: false,
      canLeaveTheSphere: false
    }
  },
  mounted: function() {
    store.set('currentPage', 'ArExperience');
    store.set('ar', true);
    store.set('attempt', 0)
    store.set('centerButton', 'record');

    var _self = this;
    this.scanSub = store.sub(
      ['mapShown', 'centerButton', 'lost', 'scannedQR'],
      function (mapShown, centerButton,  lost, qr){
        _self.mapShown = mapShown;
        _self.lost = lost;
        if(mapShown){
          _self.centerButton = 'qr';
        }else{
          if(lost){
            _self.centerButton = 'map';
          }else{
            if(qr){
              _self.centerButton = 'record';
            }else{
              _self.centerButton = 'map';
            }
          }
        }
        console.log(_self.centerButton);
      });

    this.scanSub2 = store.sub(
      [ 'prizeNotify', 'enterTheRoomButton', 'inSphere'],
      ( prizeNotify, enterTheRoomButton, inSphere) => {
        this.prizeNotify = prizeNotify;
        this.clickable = enterTheRoomButton;
        this.inSphere = inSphere;
      });
    this.scanSub3 = store.sub(
      [ 'keyDirection', 'scannedQR', 'centerButton'],
      ( keyDirection, scannedQR, centerButton) => {
        this.keyDirection = keyDirection && scannedQR === 'key' && centerButton === 'record';
      });

    this.scanSub4 = store.sub(
      [ 'sawPrize', 'canLeaveTheSphere'],
      ( sawPrize, canLeaveTheSphere ) => {
        this.sawPrize = sawPrize;
        this.canLeaveTheSphere = canLeaveTheSphere;
      });

  },
  unmounted: function() {
    // store.set('ar', false);
    this.scanSub && this.scanSub();
    this.scanSub2 && this.scanSub2();
    this.scanSub3 && this.scanSub3();
    this.scanSub4 && this.scanSub4();
    //this.scanSub2 && this.scanSub2();
  },
  methods: {
    nope: function(e) {
      e.preventDefault();
      e.stopPropagation();
    },
    fromSphere: function() {
      store.set('scannedQR', false);
      ARController.setInSphere(false);
    },
    clickedClickable: function(e) {
      this.nope(e);
      ARController.setInSphere(true);
      store.set( {
        'canLeaveTheSphere': false,
        sawPrize: false
      });

      setTimeout(function() {
        router.push({path: 'ar-view'});
        store.set('canLeaveTheSphere', true);


      }, 11000);
    },
    qrclick: function() {
      this.scanFrame = true;
      store.set('scannedQR', false);
      store.set('ar', true);
      store.set({
        mapShown: false,
        keyDirection: false
      });
    },
    mapclick: function(){
      store.set( {
        'ar': false,
        'keyDirection': false,
        mapShown: true
      } );
    },
    down: function(e) {

      if(this.centerButton !== 'record')
        return;

      var target = e.currentTarget;
      target.classList.add('down');
      this.start = +new Date();

      console.log('down')
      e.preventDefault();
      e.stopPropagation();

      this.videoRecordTimeout = setTimeout(()=>{
        target.classList.add('video');
        this.recordVideo();
      }, 700)
    },
    makeAPhoto: function() {
      console.log('take a photo');
      ARController.takeAPhoto();
    },
    recordVideo: function() {
      console.log('record video');
      ARController.recordVideo();
      store.set({
        videoRecording: true
      });
      this.videoRecording = true;
    },
    up: function(e) {
      var target = D('.camera-controls__button');
      target.forEach(el=>{
        el.classList.remove('down')
        el.classList.remove('video')
      })

      if(this.centerButton !== 'record')
        return;

      var clickDuration =+new Date() - this.start;

      if(clickDuration < 700){
        clearTimeout(this.videoRecordTimeout);
        this.makeAPhoto();
      }else{
        if(this.videoRecording)
          ARController.stopRecording();
      }

      console.log('up', clickDuration)
    }
  }
}
</script>

<style lang="scss">

.clickable-area {
  position: absolute;
  top: 20%;
  left: 20%;
  width: 60%;
  height: 60%;
  z-index: 1;
}

.ar-experience {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.ar-experience__qr-frame {
  position: absolute;
  z-index: 11;
  left: 50%;
  top: 66%;
  transform: translate(-50%, -50%) scale(75%, 75%);
  text-align: center;
}
.ar-experience__map {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
</style>
